<template>
  <div>
    <div class="header">
      <p class="header-title">{{ header_config.title }}</p>
      <template v-if="user.token_unique">
        <a class="user_info" href="/ourself">{{ user.nickname }}</a>
        <p>|</p>
        <a @click="outlogin" class="user_info">退出登录</a>
      </template>
      <template v-else>
        <a href="/login" class="user_info">登錄</a>
        <p>|</p>
        <a href="/register" class="user_info">註冊</a>
      </template>
    </div>
    <div class="header-container">
      <div class="app-max-container">
        <div class="header-about">
          <div class="header-log">
            <a href="/" target="_blank">
              <img class="logo" :src="header_config.pc_logo" />
            </a>
          </div>
          <div class="header-contact">
            <a class="header-contact-info" target="_blank" :href="'https://api.whatsapp.com/send/?phone='+header_config.whatapp+'&text&type=phone_number&app_absent=0'">
              <p class="header-contact-item">聯繫我們</p>
              <p class="header-contact-item">
                <img src="@/assets/icon/phone.png" alt="服務熱綫" />
                {{ header_config.phone }}
              </p>
              <p class="header-contact-item">
                <img src="@/assets/icon/email.png" alt="服務郵箱" />
                {{ header_config.email }}
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      header_config: {
        title: "香港鐵木建材有限公司自成立以來，一直為廣大客戶提供安全、可靠、優質的建築材料及施工用品",
        pc_logo: "https://api.genghis.hk/uploads/images/20240511/202405112219342e29f6744.png",
        phone: "+852 6213 9561",
        email: "Info@genghis.hk",
        whatapp:"85262139561"
      },
      user: {
        nickname: "",
        email: "",
        token: ""
      }
    };
  },
  created() {
    var user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      this.user = user;
    }
  },
  methods: {
    outlogin(){
      localStorage.removeItem("user");
      localStorage.removeItem("token_unique");
      this.$router.push({ path: "/login"});
    }
  }
};
</script>
<style lang="scss" scoped>
.header {
  width: 100%;
  height: 28px;
  line-height: 28px;
  background: #000000;
  color: #fff;
  text-align: center;
  font-size: 12px;
  display: flex;
  .header-title {
    flex: 1;
  }
  .user_info {
    width: 100px;
    text-align: center;
  }
}
.header-container {
  background: #f6f6f6;
}
.header-about {
  display: flex;
  align-items: center;
  height: 100px;

  .header-log,
  .header-contact {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .header-contact {
    align-items: flex-end;
    .header-contact-info {
      width: 200px;
      display: flex;
      flex-direction: column;
      align-items: end;

      .header-contact-item {
        color: #002557;
        font-family: HarmonyOS_Sans_SC;
        font-weight: 500;
        line-height: 24px;
        font-size: 20px;
        display: flex;
        justify-content: center;

        img {
          margin-right: 5px;
        }
      }
    }
  }

  .header-log {
    .logo {
      width: 450px;
    }
  }
}
</style>

<template>
  <div>
    <appHeader></appHeader>
    <appNav></appNav>
    <div class="address_box">
      <div class="title">我的地址</div>
      <el-form ref="form" :model="address" :inline="true" label-width="80px" class="address">
        <div class="address_one">
          <el-form-item label="收貨人">
            <el-input  v-model="address.contact_person" style="width: 270px;"></el-input>
          </el-form-item>
          <el-form-item label="收貨電話">
            <el-input  v-model="address.contact_phone" style="width: 270px;"></el-input>
          </el-form-item>
          <el-form-item label="聯係郵箱">
            <el-input  v-model="address.contact_email" style="width: 270px;"></el-input>
          </el-form-item>
        </div>
        <div class="address_two">
          <el-form-item label="收穫地址">
            <el-cascader ref="cascaderRef" @change="selectAddress" :props="{ value: 'area_code', label: 'title' }"
              clearable filterable :options="addressInfo" v-model="addressModelValue" style="width: 450px">
            </el-cascader>
          </el-form-item>
          <el-form-item label="詳細地址">
            <el-input  type="input  " v-model="address.address_info"
              style="width: 270px;"></el-input>
          </el-form-item>
          <el-form-item label="" style="width: 270px;">
          </el-form-item>
        </div>
        <!-- <div class="address_two">
          <el-form-item label="詳細地址">
            <el-input  type="input  " v-model="address.address_info"
              style="width: 270px;"></el-input>
          </el-form-item>
          <el-form-item label="備注">
            <el-input type="input" v-model="address.remake" style="width: 270px;"></el-input>
          </el-form-item>
          <el-form-item label="" style="width: 270px;">
          </el-form-item>
        </div> -->
      </el-form>
    </div>
    <div class="login_box">
      <div class="wenb">我的購物車</div>
      <div class="cart_main">
        <div class="main_pone">
          <div class="main_zt_one" style="padding-left: 266px">產品名稱</div>
          <!-- <div class="main_zt_one" style="padding-left: 420px">价格范围</div> -->
          <div class="main_zt_one" style="padding-left: 420px">數量</div>
        </div>
        <div class="main_p_line">
          <div class="line"></div>
        </div>
        <div v-if="cartList.length == 0" class="cartListNull">暫未選擇產品</div>
        <div v-for="(item, index) in cartList" :key="index">
          <div class="main_ptwo">
            <div class="main_ptwo_left">
              <div class="img_one">
                <img class="product_image" :src="item.sku.sku_image" alt=""
                  @click="jumpProductDetails(item.product.id)" />
              </div>
            </div>
            <div class="main_ptwo_right">
              <div class="main_ptwo_right_top">
                <div class="main_zt_two" @click="jumpProductDetails(item.product.id)">{{ item.product.product_title }}
                </div>
                <!-- <div class="price_box_price" v-if="item.product.max_product_price == item.product.product_price">${{ item.product.product_price}}</div>
                <div class="price_box_price" v-else>${{ item.product.product_price}} ~ {{ item.product.max_product_price}}</div> -->

                <NumberBox v-model="item.cart_number" :min="1" :max="item.sku.sku_stock" />
                <div class="top_end">
                  <i class="el-icon-delete" @click="delCartProduct(item)"></i>
                </div>
              </div>
              <div class="main_shortline">
                  {{ item.sku.sku_name }}
              </div>
              <div class="main_shortline-no">
                 <span style="padding-right: 20px;">产品备注</span> <el-input type="input" v-model="cartRemakeList[index].remake" style="width: 270px;"></el-input>
                </div>
            </div>
          </div>
          <div class="main_p_line">
            <div class="line"></div>
          </div>
        </div>
      </div>

      <div class="cart_footer">
        <div class="price_end_box">
          <!-- <div class="price_box_title_diff">
            <div>合計</div>
          </div> -->
          <!-- <div class="price_box_end_price">${{ orderAmount() }}</div> -->
          <div class="price_box_end" @click="submitOrder">
            <div>確認訂單</div>
          </div>
        </div>
      </div>
    </div>
    <appFooter></appFooter>
  </div>
</template>
<script>
import appHeader from "@/components/header.vue";
import appFooter from "@/components/footer.vue";
import appNav from "@/components/nav.vue";
import { userCartProductApi, userCartCreateOrderApi, onChangeUserCartApi, userAddressApi, delUserCartProductApi } from "@/api/index.js";
import NumberBox from "@/components/numberBox";
import { validatePhoneNumber } from "@/utils/phone.js";
import { validateEmailNumber } from "@/utils/email.js";
import AreaJson from "@/json/area.json";

export default {
  components: {
    appHeader,
    appFooter,
    appNav,
    NumberBox
  },
  data() {
    return {
      account: "",
      password: "",
      passwordState: true,
      cartList: [],
      cartRemakeList:[],
      priceInfo: {},
      order_amount: 0,
      address: {
        id: "",
        address_info: "",
        contact_email: "",
        contact_phone: "",
        contact_person: "",
        remake: "",
        province_name:"",
        province_id:"",
        city_name:"",
        city_id:"",
        area_name:"",
        area_id:"",
      },
      addressInfo: AreaJson,
      addressModelValue:[]
    };
  },
  created() {
    this.userCartProduct();
    this.userAddress();
  },
  methods: {
    selectAddress(address_info) {
      console.log(address_info)
      this.address.province_id = address_info[0];
      this.address.city_id = address_info[1];
      this.address.area_id = address_info[2];

      var city_label = [];
      AreaJson.forEach((item, index) => {
        if (item.area_code == address_info[0]) {
          city_label[0] = item.title;
          item.children.forEach((city, ind) => {
            if (city.area_code == address_info[1]) {
              city_label[1] = city.title;
              city.children.forEach((area, ins) => {
                if (area.area_code == address_info[2]) {
                  city_label[2] = area.title;
                }
              })
            }
          })
        }
      });
      this.address.province_name = city_label[0];
      this.address.city_name = city_label[1];
      this.address.area_name = city_label[2];
    },
    orderAmount() {
      var orderAmount = 0;
      this.cartList.forEach((item, index) => {
        orderAmount = (orderAmount * 1 + (item.sku.pay_price * 1 * item.cart_number * 1)).toFixed(2)
      })
      return orderAmount;
    },
    userCartProduct() {
      var that = this;
      userCartProductApi().then((res) => {
        that.cartList = res.data;
        var order_amount = 0;
        var goods_price = 0;

        that.cartList.forEach((cart) => {
          goods_price = goods_price + Number(cart.sku.pay_price);
          that.cartRemakeList.push({
              id:cart.id,
              remake:""
            })
        });
        order_amount = goods_price;
        that.priceInfo.goods_price = goods_price;
        that.priceInfo.order_amount = order_amount;
      });
    },
    userAddress() {
      var that = this;

      userAddressApi().then((res) => {
        that.address = res.data;
        that.addressModelValue = [res.data.province_id,res.data.city_id,res.data.area_id]
      });
    },
    
    showPassword() {
      this.passwordState = !this.passwordState;
    },
    submitOrder() {
      var that = this;
      var address = that.address;
      var cartList = that.cartList;
      if (address.contact_person == "") {
        this.$message.error("收貨人不能爲空");
        return;
      }
      if (address.contact_phone == "") {
        this.$message.error("收貨電話不能爲空");
        return;
      }
      if (address.contact_email == "") {
        this.$message.error("聯係郵箱不能爲空");
        return;
      }
      if (address.province_name == "" || address.city_name == "" || address.area_name == "") {
        that.$message.error("请完善收貨地址信息");
        return;
      }
      if (address.address_info == "") {
        this.$message.error("詳細地址不能爲空");
        return;
      }
      
      
     
      //郵箱校驗
      //手机号码校驗

      var email_reg = validateEmailNumber(address.contact_email);
      var mobile_reg = validatePhoneNumber(address.contact_phone);

      if (!mobile_reg) {
        that.$message.error("请輸入正確手機號碼");
        return;
      }
      if (!email_reg) {
        that.$message.error("请輸入正確郵箱");
        return;
      }

      userCartCreateOrderApi({
        address_info: address.address_info,
        address_id: address.id,
        province_name:address.province_name,
        province_id:address.province_id,
        city_id:address.city_id,
        city_name:address.city_name,
        area_name:address.area_name,
        area_id:address.area_id,
        contact_person: address.contact_person,
        contact_email: address.contact_email,
        contact_phone: address.contact_phone,
        remake: address.remake,
        cartRemakeList:that.cartRemakeList,
      }).then((res) => {
        if (res.code == 200) {
          that.$message.success("下單成功，請靜待業務員聯係");
          that.$router.push({ path: "/ourself"});
          that.userCartProduct();
        } else {
          that.$message.error(res.msg)
        }
      });
    },
    onChangeCart() {
      var that = this;
      var cartList = this.cartList;
      var cartInfo = [];
      cartList.forEach((element) => {
        cartInfo.push({ id: element.id, goods_num: element.goods_num });
      });
      onChangeUserCartApi({ cartInfo: cartInfo }).then((res) => {
        this.userCartProduct();
      });
    },
    delCartProduct(item,index) {
      var that = this;
      delUserCartProductApi({ id: item.id })
        .then((res) => {
          that.$message.success("刪除成功");
          that.cartRemakeList.splice(index,1);
          that.cartList.splice(index,1);

          // this.userCartProduct();
        })
        .catch((res) => {
          that.$message.success(res.msg);
        });
    },
    jumpProductDetails(goods_id) {
      this.$router.push({ path: "/details/" + goods_id });
    }
  }
};
</script>
<style lang="scss" scoped>
.cart_main {
  .main_pone {
    width: 1176px;
    padding-top: 20px;
    padding-bottom: 17px;
    display: flex;
    justify-content: flex-start;
  }

  .main_p_line {
    display: flex;
    width: 1176px;
    height: 1px;

    .line {
      width: 1127px;
      height: 1px;
      background: #8c8c8c;
      margin-left: 26px;
    }
  }

  .main_ptwo {
    display: flex;

    .main_ptwo_left {

      // width: 252px;
      .img_one {
        width: 158px;
        height: 132px;
        margin: 40px;

        // background-color: #2e4f76;
        // margin-left: 79px;
        // margin-top: 40px;
        // margin-bottom: 37px;
        .product_image {
          width: 158px;
          cursor: pointer;
          // height: 158px;
        }
      }
    }

    .main_ptwo_right {
      .main_ptwo_right_top {
        display: flex;
        justify-content: flex-start;
        // height: 100px;
        padding: 20px 0;
        align-items: center;

        // padding-bottom: 14px;
        // padding-top: 18px;
        .main_zt_two {
          cursor: pointer;
          width: 400px;
          padding-left: 12px;
        }

        .price_box_price {
          width: 250px;
          justify-items: center;

          // padding-left: 146px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .background_a {
          width: 80px;

          // display: flex;
          // align-items: center;
          // justify-content: center;
          // width: 41px;
          // height: 27px;
          // background: #FFFFFF;
          box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.35);
          border-radius: 5px;
          border: 1px solid #464646;
          // margin-left: 60px;
          // text-align: center;
        }

        .main_zt_three {
          width: 150px;
          padding-left: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .top_end {
          width: 19px;
          height: 25px;
          color: #2e4f76;
          font-size: 25px;
          margin-left: 50px;
        }
      }

      .main_ptwo_right_bottom {
        height: 151px;

        .text_box_one {
          margin-top: 10px;
        }
      }
      .main_shortline-no{
        display: flex;
        width: 924px;
        padding: 20px;
      }
      .main_shortline {
        display: flex;
        width: 924px;
        border-top: 1px solid #d1d1d1;
        padding: 20px;
        
      }
    }
  }
}

.cart_footer {
  .price_box {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 22px;

    .price_box_price {
      width: 170px;
      text-align: center;
    }
  }

  .price_end_box {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 57px;
    padding: 20px 0;

    .price_box_title_diff {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .price_box_end_price {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 17px;
    }

    .price_box_end {
      cursor: pointer;
      width: 145px;
      height: 45px;
      background: #2e4f76;
      border-radius: 17px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 42px;
      margin-right: 59px;
    }
  }
}

.wenb {
  font-family: Microsoft YaHei;
  font-weight: bold;
  font-size: 24px;
  color: #2e4f76;
  padding-left: 36px;
  padding-top: 49px;
}

.main_zt_one {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 18px;
  color: #464646;
}

.main_zt_two {
  font-family: Microsoft YaHei;
  font-weight: bold;
  font-size: 24px;
  color: #2e4f76;
}

.main_zt_three {
  font-family: Microsoft YaHei;
  font-weight: bold;
  font-size: 18px;
  color: #464646;
}

.price_box_title {
  font-weight: bold;
  font-size: 18px;
  color: #2e4f76;
}

.price_box_title_diff {
  font-weight: bold;
  font-size: 18px;
  color: #2e4f76;
}

.price_box_price {
  font-weight: 400;
  font-size: 18px;
  color: #464646;
}

.price_box_end_price {
  font-family: Microsoft YaHei;
  font-weight: bold;
  font-size: 24px;
  color: #2e4f76;
}

.price_box_end {
  font-family: Microsoft YaHei;
  font-weight: bold;
  font-size: 24px;
  color: #ffffff;
}

.login_box {
  width: 1176px;
  background: #ffffff;
  box-shadow: 0px 6px 8px 2px rgba(0, 0, 0, 0.14);
  border-radius: 5px;
  border: 1px solid #464646;
  margin: 100px auto;
}

.address_box {
  width: 1176px;
  background: #ffffff;
  box-shadow: 0px 6px 8px 2px rgba(0, 0, 0, 0.14);
  border-radius: 5px;
  border: 1px solid #464646;
  margin: 100px auto;
  padding: 40px;

  .title {
    font-family: Microsoft YaHei;
    font-weight: bold;
    font-size: 24px;
    color: #2e4f76;
  }

  .address {
    padding: 30px 0;

    .address_one {
      display: flex;
      justify-content: space-between;
    }

    .address_two {
      display: flex;
      justify-content: space-between;
    }

    // display: flex;
    // justify-content: space-between;
    //   font-weight: 400;
    // font-size: 18px;
    // color: #464646;
  }
}

.cartListNull {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  font-size: 24px;
  color: #464646;
}
</style>
